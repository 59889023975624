import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const SliderStyled = styled.div`
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`;

const Slide = styled.div`
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  transition: opacity 420ms ease-in;
  top: 0;
  z-index: 0;
  width: 100%;

  &.active {
    opacity: 1;
    z-index: 1;
  }
`;

export default class Slider extends Component {
  state = {
    activeIndex: 0,
    autoplay: this.props.autoplay
  };

  componentDidMount = () => {
    if (this.props.autoplay) {
      this.intervalId = setInterval(() => {
        this.nextSlide();
      }, 5000);
    }
  };

  componentWillUnmount = () => {
    clearInterval(this.intervalId);
  };

  nextSlide = () => {
    let newIndex = this.state.activeIndex + 1;
    if (newIndex >= this.props.children.length) {
      newIndex = 0;
    }
    this.setState({ activeIndex: newIndex });
  };

  render() {
    const { children } = this.props;
    const { activeIndex } = this.state;
    return (
      <SliderStyled>
        {React.Children.map(children, (child, i) => {
          return (
            <Slide
              key={i}
              className={activeIndex === i ? "active" : "inactive"}
            >
              {child}
            </Slide>
          );
        })}
      </SliderStyled>
    );
  }
}

Slider.defaultProps = {
  autoplay: true
};
