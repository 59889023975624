import React from "react";
import { withRouteData } from "react-static";
//
import Homepage from "components/homepage";
import Template from "containers/template";
//
export default withRouteData(({ data }) => (
  <Template>
    <Homepage {...data} />
  </Template>
));
