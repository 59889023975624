import React from "react";
import styled from "styled-components";
//
import Slider from "components/slider";
import Slide from "./slide";
//
export default ({ slideshow, text }) => (
  <Wrap>
    <SliderWrap>
      <Slider>
        {slideshow.map((slide, i) => (
          <Slide key={i} {...slide} />
        ))}
      </Slider>
    </SliderWrap>
  </Wrap>
);
//
const Wrap = styled.div``;
const SliderWrap = styled.div`
  height: 100vh;
  height: calc(100vh - 205px);
  position: relative;
  width: 100%;
`;
