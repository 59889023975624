import React from "react";
import styled from "styled-components";
//
import { ImageBg, RichText } from "components/prismic";
//
export default ({ caption, image, ...rest }) => (
  <Wrap {...rest}>
    <ImageBg image={image} />
    {caption && <RichText className="text" {...caption} />}
  </Wrap>
);
//
const Wrap = styled.div`
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;

  .text {
    color: ${({ color }) => color};
    display: inline-block;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 1rem;
    text-align: center;
    top: 1rem;
    z-index: 1;
  }
`;
